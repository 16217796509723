<template>
	<div class="admin_manage">
		<el-card>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true" class="demo-form-inline">
            <el-form-item label="城市" prop="city_id" style="text-align: left;">
              <treeselect v-model="queryForm.city_id" :options="city_list" :defaultExpandLevel="Infinity"
                          :normalizer="normalizer_city" :show-count="true" placeholder="请选择运营城市" flat style="width: 180px;"/>
            </el-form-item>
            <el-form-item label="姓名:">
              <el-input v-model="queryForm.name" placeholder="请输入姓名" clearable></el-input>
            </el-form-item>
            <el-form-item label="手机号:">
              <el-input v-model="queryForm.phone" placeholder="请输入手机号" clearable></el-input>
            </el-form-item>
            <el-form-item label="服务类型">
              <el-select v-model="queryForm.type_id" placeholder="请选择服务类型" clearable width="100%" style="display: block;">
                <el-option v-for="item in typeList" :key="item.type_id" :label="item.type_name" :value="item.type_id"/>
              </el-select>
            </el-form-item>
            <el-form-item label="入驻状态">
              <el-select v-model="queryForm.entry_status" placeholder="请选择入驻状态" clearable width="100%" style="display: block;">
                <el-option v-for="item in entryStatusList" :key="item.value" :label="item.name" :value="item.value"/>
              </el-select>
            </el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-row class="left mb30">
				<el-col :span="24">
					<el-button type="primary" size="mini" icon="el-icon-plus" @click="add">新增城市合伙人</el-button>
<!--					<el-button type="danger" size="mini" icon="el-icon-delete" @click="removeAll">删除</el-button>-->
				</el-col>
			</el-row>
<!--      @select-all="selectAll"@select="select" -->
			<el-table border :data="adminList"  style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
<!--				<el-table-column fixed type="selection"></el-table-column>-->
				<el-table-column label="ID" prop="admin_id" width="60"></el-table-column>
<!--        <el-table-column label="头像">-->
<!--          <template slot-scope="scope">-->
<!--            <div class="cell el-tooltip" style="width: 58px;">-->
<!--							<span class="el-avatar el-avatar&#45;&#45;square" style="height: 25px; width: 25px; line-height: 25px;">-->
<!--								<el-image :src="scope.row.admin_logo" fit="fit"></el-image>-->
<!--							</span>-->
<!--            </div>-->
<!--          </template>-->
<!--        </el-table-column>-->
				<el-table-column :show-overflow-tooltip="true" label="姓名" prop="admin_name"></el-table-column>
				<el-table-column prop="sex" label="性别" >
					<template slot-scope="scope">
						<el-tag :type="scope.row.sex === 1 ? 'primary' : 'success'" disable-transitions>{{scope.row.sex_name}}</el-tag>
					</template>
				</el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="账号" prop="account"></el-table-column>
        <el-table-column label="手机号">
          <template slot-scope="scope">
            <div v-if="scope.row.wx_info&&scope.row.wx_info.phoneNumber">{{scope.row.wx_info.phoneNumber}}</div>
          </template>
        </el-table-column>
<!--				<el-table-column label="服务电话" prop="phone" width="130"></el-table-column>-->
				<el-table-column :show-overflow-tooltip="true" label="服务区域" width="180">
					<template slot-scope="scope">
						<el-tag type="primary" disable-transitions v-for="(item,index) in scope.row.city_list" :key="index" style="margin-left: 2px;">{{item.city_name}}&nbsp;&nbsp;</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="上岗状态" width="100">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.admin_status" @change="setAdminStatus(scope.row)"></el-switch>
					</template>
				</el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="服务类型" width="260">
          <template slot-scope="scope">
            <el-tag type="primary" disable-transitions v-for="(item,index) in scope.row.type_list" :key="index" style="margin-left: 5px;">{{item.type_name}}-{{item.career}}&nbsp;&nbsp;</el-tag>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="入驻状态" width="180">
          <template slot-scope="scope">
            <el-tag type="success" disable-transitions v-if="scope.row.is_entry">已入驻</el-tag>
            <el-tag type="danger" disable-transitions v-else>未入驻</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="entry_time" label="入驻时间" :show-overflow-tooltip="true" width="150"></el-table-column>
				<el-table-column label="注册时间" prop="create_time" width="150"></el-table-column>
        <!--el-table-column label="更新时间" prop="update_time" width="150"></el-table-column>-->
				<el-table-column label="操作" fixed="right" width="330">
					<template slot-scope="scope">
            <el-row>
							<el-button type="primary" size="mini" @click="update(scope.row)">修改</el-button>
<!--              <el-button type="danger" size="mini" @click="remove(scope.row)">删除</el-button>-->
							<el-button type="warning" size="mini" @click="resetPwd(scope.row)">重置密码</el-button>
<!--              <el-button type="success" size="mini" @click="goSettings(scope.row)">分佣设置</el-button>-->
            </el-row>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
		</el-card>
		<!-- 添加/更新 -->
		<el-dialog :title="title" width="600px" top="5vh" :visible.sync="addVisible">
			<el-form ref="addRef" :model="addForm" :rules="addRules" label-width="150px" class="left">
				<el-row>
<!--					<el-col :span="24">-->
<!--						<el-form-item label="头像:">-->
<!--							<div class="previewUpload">-->
<!--								<div v-if="addForm.admin_logo!=''" class="previewItem">-->
<!--									<img :src="addForm.admin_logo" alt="">-->
<!--									<i class="el-icon-close delIcon" @click="delAdminLogo"></i>-->
<!--								</div>-->
<!--								<div class="previewItems" v-else>-->
<!--									<el-upload ref="upload" style="width:100px" list-type="picture" action="https://jsonplaceholder.typicode.com/posts/" accept="image/*" :auto-upload="false" :show-file-list="false" :multiple=true :on-change="handlerChange">-->
<!--										<i class="el-icon-plus previewadd"></i>-->
<!--									</el-upload>-->
<!--								</div>-->
<!--							</div>-->
<!--						</el-form-item>-->
<!--					</el-col>-->
          <el-col :span="22">
            <el-form-item label="关联用户:" prop="wx_id">
              <WxUserSelect :wxInfo="wxInfo" @change="handleWxInfoChange" :update="!addForm.admin_id"></WxUserSelect>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="账号:" prop="account">
              <el-input v-model="addForm.account" maxlength="20" placeholder="请输入账号" :disabled="addForm.admin_id?'disabled':false"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22" v-if="!addForm.admin_id">
            <el-form-item label="密码:" prop="password">
              <el-input v-model="addForm.password" placeholder="请输入密码" :disabled="addForm.admin_id?'disabled':false"></el-input>
            </el-form-item>
          </el-col>
					<el-col :span="22">
						<el-form-item label="真实姓名:" prop="admin_name">
							<el-input v-model="addForm.admin_name" placeholder="请输入姓名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="22">
						<el-form-item label="性别:" prop="sex">
							<el-radio v-model="addForm.sex" label="1">男</el-radio>
							<el-radio v-model="addForm.sex" label="2">女</el-radio>
						</el-form-item>
					</el-col>
          <el-col :span="22">
            <el-form-item label="手机号:" prop="phoneNumber">
              <el-input v-model="addForm.phoneNumber" placeholder="请输入手机号" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="商城收益分佣比例:" prop="shop_commission">
              <el-input v-model.number="addForm.shop_commission" placeholder="请输入商城收益分佣比例"></el-input>
            </el-form-item>
          </el-col>
<!--					<el-col :span="22">-->
<!--						<el-form-item label="邮箱:" prop="email">-->
<!--							<el-input v-model="addForm.email" placeholder="请输入邮箱"></el-input>-->
<!--						</el-form-item>-->
<!--					</el-col>-->
<!--					<el-col :span="12">-->
<!--						<el-form-item label="角色:" prop="role_id">-->
<!--							<el-select v-model="addForm.role_id" multiple placeholder="请选择" style="width: 100%;">-->
<!--								<el-option v-for="item in role_list" :key="item.value" :label="item.label" :value="item.value">-->
<!--								</el-option>-->
<!--							</el-select>-->
<!--						</el-form-item>-->
<!--					</el-col>-->
					<el-col :span="22" class="select-city">
						<el-form-item label="选择城市" prop="city_id" style="text-align: left;">
							<treeselect v-model="addForm.city_id" :options="city_list" multiple :defaultExpandLevel="Infinity"
                          :normalizer="normalizer_city" :show-count="true" placeholder="请选择运营城市"
                          flat/>
						</el-form-item>
					</el-col>
          <el-col :span="22">
            <el-form-item label="服务类型:" prop="type_id">
              <el-select v-model="addForm.type_id" placeholder="请选择服务类型" multiple clearable style="width: 100%;">
                <el-option
                    v-for="item in typeList"
                    :key="item.type_id"
                    :label="item.type_name"
                    :value="item.type_id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="服务电话:" prop="phone">
              <el-input v-model="addForm.phone" placeholder="请输入手机号码用于接收短信信息"></el-input>
            </el-form-item>
          </el-col>
<!--					<el-col :span="24">-->
<!--						<el-form-item label="个人简介:" prop="remark">-->
<!--							<el-input type="textarea" :rows="4" v-model="addForm.remark" placeholder="请输入个人简介"></el-input>-->
<!--						</el-form-item>-->
<!--					</el-col>-->
				</el-row>
			</el-form>
			<div>
				<el-button type="default" @click="addClose">取消</el-button>
				<el-button type="primary" @click="addSub">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {serviceTypeAll} from "@/api/request/service";
  import WxUserSelect from "@/components/Form/WxUserSelect";
  let that;
	let timer = null;
	import Treeselect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import Pagination from '@/components/Pagination/Pagination.vue'
  import {addOrUpdate, deletePartner, getPartnerList} from "@/api/request/partner";
  import {isPhoneNumber, isPureNumber, isTelNumber} from "@/api/utils";
	export default {
		components: {
			Treeselect,Pagination,WxUserSelect
		},
		data() {
			return {
        queryForm: {
          city_id: null,
          name: '',
          phone: '',
          type_id: '',
          entry_status: '',
        },
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				title: '新增城市合伙人',
				total: 0,
				// account: '',
				// admin_name: '',
        // type_id: '',
        // entry_status: '',
				// role_list: [],
				adminList: [],
				chooseList: [],
				addVisible: false,
				fenpeiVisible: false,
				fileData: {},
				addForm: {
					admin_id: '',
					// admin_logo: '',
          account: '',
          password: '',
					admin_name: '',
					sex: '1',
					phone: '',
					// email: '',
					// remark: '',
					// role_id: '',
					// dept_id: null,
					city_id: null,
          type_id: null,
          wx_id: null,
          shop_commission: '',
          phoneNumber: '',
				},
				addRules: {
					admin_name: [{required: true, message: '请输入姓名', trigger: 'blur'}],
					account: [{required: true, message: '请输入账号', trigger: 'blur'},
            { validator: (rule, value, callback) => {
                if (isPureNumber(value)) {
                  return callback(new Error('账号不能为纯数字'));
                }
                callback();
              }, trigger: 'blur'}],
					password: [
            {required: true, message: '请输入密码', trigger: 'blur'},
            { validator: (rule, value, callback) => {
              if (!this.addForm.admin_id) {
                if (value.length <= 0) return callback(new Error('请输入密码'));
                if (value.length < 6) return callback(new Error('请输入至少6位密码'));
                if (value.length > 6) return callback(new Error('请输入最多16位密码'));
              }
              callback();
            }, trigger: 'blur'}],
          city_id: [{required: true, message: '请选择城市', trigger: 'blur'}],
          type_id: [
            {required: true, message: '请选择服务类型', trigger: 'blur'},
            { validator: (rule, value, callback) => {
              if (value.length <= 0) return callback(new Error('请选择服务类型'));
              callback();
            }, trigger: 'blur'}],
          wx_id: [{required: true, message: '请输入选择关联用户', trigger: 'blur'}],
          phoneNumber: [{required: true, message: '请输入手机号', trigger: 'blur'},
            { validator: (rule, value, callback) => {
                if (!isPhoneNumber(value)) {
                  return callback(new Error('请输入正确的手机号'));
                }
                callback();
              }, trigger: 'blur'}
          ],
          phone: [{required: true, message: '请输入输入服务电话', trigger: 'blur'},
            { validator: (rule, value, callback) => {
                if (!isTelNumber(value)) {
                  return callback(new Error('请输入正确的服务电话号码'));
                }
                callback();
              }, trigger: 'blur'}
          ],
          shop_commission: [{required: true, message: '请输入商城收益分佣比例', trigger: 'blur'},
            { validator: (rule, value, callback) => {
                if (value < 0) return callback(new Error('商城收益分佣比例不能为负数'));
                if (value > 99.99) return callback(new Error('商城收益分佣比例不能超过100'));
                callback();
              }, trigger: 'blur'}],
				},
        wxInfo: {},
				city_list: [],
				// deptList: [],
        typeList:[],
        entryStatusList:[
          {name: '已入驻',value: 1},
          {name: '未入驻',value: 2}
        ],
			}
		},
		created() {
			that = this;
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				this.getCityList();
				this.getAdminList();
				this.getServiceType();
			}
		},
		methods: {
      async getServiceType() {
        const res = await serviceTypeAll({})
        if (res.code!==200) this.$message.error(res.msg);
        this.typeList = res.data;
      },
			getCityList(){
        var url = 'city/city_list';
				let params = {
					is_del: 1
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.city_list = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
      },
			async getAdminList() {
        let params = {
          // account: this.account,
          // admin_name: this.admin_name,
          size: this.queryInfo.pagesize,
          page: this.queryInfo.pagenum,
          ...this.queryForm
        };
        const res = await getPartnerList(params).catch((err) => {
          this.$message.error('网络错误');
        });
        if(res.code === 200) {
          this.total = res.total;
          this.adminList = res.rows;
        } else {
          this.$message.error(res.msg);
        }
			},
			//转换菜单数据结构
			normalizer_city(node) {
				if(node.children && !node.children.length) {
					delete node.children;
				}
				return {
					id: node.city_id,
					label: node.city_name,
          isDisabled: node.parent_id?false:true,
					children: node.children,
				};
			},
			//查询
			search() {
        this.queryInfo.pagenum = 1;
				this.getAdminList();
			},
			// 重置
			reset() {
        this.queryInfo.pagenum = 1;
				this.queryForm = {
          city_id: null,
          name: '',
          phone: '',
          type_id: '',
          entry_status: '',
        };
				this.getAdminList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getAdminList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getAdminList();
			},
			//单选
			select(selection, row) {
				this.chooseList = selection
				console.log(this.chooseList);
			},
			//全选
			// selectAll(selection) {
			// 	this.chooseList = selection
			// 	console.log(this.chooseList);
			// },
			//添加弹窗打开
			add() {
				this.title = '新增城市合伙人';
				this.addForm = {
          admin_id: '',
          account: '',
          password: '',
          admin_name: '',
          sex: '1',
          phoneNumber: '',
          phone: '',
          // email: '',
          city_id: null,
          type_id: null,
          wx_id: null,
				};
        this.wxInfo = {}
				this.addVisible = true;
			},
			update(item) {
				this.title = '编辑城市合伙人';
				this.addForm.admin_id = item.admin_id;
				this.addForm.account = item.account;
				this.addForm.admin_name = item.admin_name;
				this.addForm.sex = item.sex;
				this.addForm.phoneNumber = item.wx_info ? item.wx_info.phoneNumber : "";
				this.addForm.phone = item.phone;
				this.addForm.shop_commission = item.shop_commission;
				// this.addForm.email = item.email;
				this.addForm.city_id = item.city_id;
				this.addForm.wx_id = item.wx_id;
        this.addForm.type_id = item.type_list && item.type_list.length > 0 ? item.type_list.map(item => item.type_id) : null;
        this.wxInfo = item.wx_info ? item.wx_info : {};
        console.log(this.addForm.type_id)
				this.addVisible = true;
			},
			//关闭添加弹窗
			addClose() {
				this.$refs.addRef.resetFields()
				// this.addForm.role_desc = ''
				this.addVisible = false
			},
			//确认添加
			async addSub() {
        // console.log("【提交表单】",this.addForm);return
				this.$refs.addRef.validate(async value => {
          if (!value) return
          const res = await addOrUpdate(this.addForm).catch(error => {
            this.addVisible = false
            this.$message.error('网络错误');
          })
          this.addVisible = false
          if (res.code === 200) {
            this.$message.success('操作成功');
            this.getAdminList();
          } else {
            this.$message.error(res.msg);
          }
        })
			},
			//设置职级状态
			setAdminStatus(item){
				if(timer != null){clearTimeout(timer);}
				timer = setTimeout(() => {
					var url = 'admin/setAdminStatus';
          let params = {
            admin_id: item.admin_id
          };
					that.fd_post(url, params).then((res) => {
            if(res.status){
              that.$message.success("操作成功");
            }else{
              that.$message.error(res.msg);
            }
          }).catch((err) => {
              that.$message.error('网络错误');
          });
				},300);
			},
			//删除
			async remove(item) {
				this.$confirm('删除之后，城市合伙人下的所有渠道推广员、技师、门店客户将不再属于该城市合伙人，归属于他的上级，城市合伙人的佣金也一并取消，确认删除城市合伙人的账号吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
          const res = await deletePartner({admin_id: item.admin_id}).catch((err) => {
            that.$message.error('网络错误');
          });
          if (res.code === 200) {
            this.$message.success("操作成功");
            await this.getAdminList();
          } else {
            this.$message.error(res.msg);
          }
        }).catch(() => {
					this.$message.info('已取消删除');
				});
			},
			resetPwd(item){
				this.$confirm('您确定要为'+item.admin_name+'【'+item.account+'】重置密码吗，确定后密码将会重置为【123456】？', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'admin/resetPwd';
					let params = {
						admin_id: item.admin_id,
					};
					this.fd_post(url, params).then((res) => {
						if(res.status) {
							this.$message.success('密码重置成功');
							this.getAdminList();
						} else {
							this.$message.error(res.msg);
						}
					}).catch((err) => {
						this.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消重置')
				});
			},
			//转换部门数据结构
			normalizer(node) {
				if(node.children && !node.children.length) {
					delete node.children;
				}
				return {
					id: node.dept_id,
					label: node.dept_name,
					children: node.children,
				};
			},
      handleWxInfoChange(e) {
        this.wxInfo = e;
        this.addForm.wx_id = e.wx_id;
        this.addForm.phoneNumber = e.phoneNumber;
      },
      goSettings(item){
        if (item.city_list.length === 0){
          this.$message.error('请先为城市合伙人设置城市');
          return
        }
        this.$router.push({
          path: '/city_partner_fy_setting',
          query: {
            admin_id:item.admin_id,
            admin_name:item.admin_name,
            city_list: item.city_list,
          }
        })
      },
		},
	}
</script>

<style lang="scss" scoped>
	.admin_manage {
		.left {
			text-align: left;
		}
		.mb30 {
			margin-bottom: 30px;
		}
		.trees {
			height: 300px;
			overflow-y: auto;
		}
		.previewUpload {
			display: flex;
			flex-wrap: wrap;
			.previewItems {
				width: 178px;
				height: 178px;
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				margin: 0 10px 10px 0;
				cursor: pointer;
				position: relative;
				.previewadd {
					font-size: 28px;
					color: #8c939d;
					width: 178px;
					height: 178px;
					line-height: 178px;
					text-align: center;
				}
				&:hover {
					border-color: #409EFF;
				}
			}
			.previewItem {
				width: 178px;
				height: 178px;
				border-radius: 6px;
				margin: 0 10px 10px 0;
				cursor: pointer;
				position: relative;
				.delIcon {
					display: none;
				}
				&:hover {
					.delIcon {
						display: block;
						position: absolute;
						top: 5px;
						right: 5px;
						color: white;
						font-size: 30px;
					}
				}
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
  ::v-deep .vue-treeselect--single .vue-treeselect__input {
    display: block;
  }
  .select-city{
    ::v-deep .el-form-item__content{
      line-height: normal;
    }
  }
</style>