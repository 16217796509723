import { render, staticRenderFns } from "./CityPartnerList.vue?vue&type=template&id=640cb75b&scoped=true&"
import script from "./CityPartnerList.vue?vue&type=script&lang=js&"
export * from "./CityPartnerList.vue?vue&type=script&lang=js&"
import style0 from "./CityPartnerList.vue?vue&type=style&index=0&id=640cb75b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "640cb75b",
  null
  
)

export default component.exports